import React from 'react'
import Profile from '../components/Profile'
import Tech from '../components/Tech'
import Project from '../components/Project'
import Contact from '../components/Contact'
import Footer from '../components/Footer'
import TimelineGroup from '../components/TimelineGroup'


const Home = () => {
  return (
    <>
      <Profile />
      <Tech />
      <Project />
      <TimelineGroup/>
      <Contact />
      <Footer />
    </>
  );
}

export default Home;

import React from "react";
import "./Card.css";


const card = (props) => {

    return (
        <div className="Card"  >
            <figure>
                <a href={props.pictureLink} rel="noreferrer" target="_blank">
                    <img src={props.image} className="cardImage" alt="Card"></img>
                </a>
                <figcaption className="capContainer">
                    <a href={props.pictureLink} rel="noreferrer" target="_blank">
                        <img className="code" src={props.caption1} alt="caption"></img>
                    </a>
                    <a href={props.gitLink} rel="noreferrer" target="_blank">
                        <img className="code" src={props.caption2} alt="caption"></img>
                    </a>
                </figcaption>
            </figure>

        </div >
    )
}

export default card;
import React from "react";
import "./Navbar.css"

const Navbar = () => {
    return (
        <div>
            <nav className="navbar">
                <p className="header">SWAN</p>
            </nav>
        </div>

    );
}

export default Navbar;

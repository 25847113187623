import React from 'react'
import './Tech.css'


const Tech = () => {

    return (
            <>
                <div className="techPage" >
                    <h1>Tech Stack and Skill Set</h1>
                    <div className="techLogo">
                        <figure className="react">
                            <img src="./images/react.svg" className="logo" alt="logo"></img>
                            <figcaption>React</figcaption>
                        </figure>
                        <figure className="java">
                            <img src="./images/vue.png" className="logo" alt="logo"></img>
                            <figcaption>Vue</figcaption>
                        </figure>
                        <figure className="html5">
                            <img src="./images/html5.png" className="logo" alt="logo"></img>
                            <figcaption>HTML</figcaption>
                        </figure>
                        <figure className="css">
                            <img src="./images/css.png" className="logo" alt="logo"></img>
                            <figcaption>CSS</figcaption>
                        </figure>
                        <figure className="typescript">
                            <img src="./images/typescript.png" className="logo" alt="logo"></img>
                            <figcaption>TypeScript</figcaption>
                        </figure>
                        <figure className="js">
                            <img src="./images/js.png" className="logo" alt="logo"></img>
                            <figcaption>JavaScript</figcaption>
                        </figure>
                        <figure className="node">
                            <img src="./images/node.png" className="logo" alt="logo"></img>
                            <figcaption>NodeJS</figcaption>
                        </figure>
                        <figure className="docker">
                            <img src="./images/java.png" className="logo" alt="logo"></img>
                            <figcaption>Java</figcaption>
                        </figure>
			            <figure className="python">
                            <img src="./images/python.png" className="logo" alt="logo"></img>
                            <figcaption>Python</figcaption>
                        </figure>
                        <figure className="django">
                            <img src="./images/django.png" className="logo" alt="logo"></img>
                            <figcaption>Django</figcaption>
                        </figure>
                        <figure className="sass">
                            <img src="./images/rest-api.svg" className="logo" alt="logo"></img>
                            <figcaption>Rest</figcaption>
                        </figure>
                        <figure className="graphql">
                            <img src="./images/graphql.png" className="logo" alt="logo"></img>
                            <figcaption>GraphQL</figcaption>
                        </figure>
                        <figure>
                            <img src="./images/c-plus-plus.svg" className="logo" alt="logo"></img>
                            <figcaption>C++</figcaption>
                        </figure>
                        <figure className="sql">
                            <img src="./images/sql.png" className="logo" alt="logo"></img>
                            <figcaption>SQL</figcaption>
                        </figure>
                        <figure className="mongodb">
                            <img src="./images/mongodb.png" className="logo" alt="logo"></img>
                            <figcaption>NoSQL</figcaption>
                        </figure>
                        <figure className="aws">
                            <img src="./images/devops.png" className="logo" alt="logo"></img>
                            <figcaption>DevOps</figcaption>
                        </figure>
                    </div>
                </div>
                <hr/>
            </>
    )
}

export default Tech;

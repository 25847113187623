import React from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import Home from './routes/Home';
import PageNotFound from './routes/PageNotFound';
import Navbar from './components/Navbar';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;

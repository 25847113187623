import React from 'react';
import Timeline from '@material-ui/lab/Timeline';
import "./Timeline.css";
import TimelineElement from './TimelineElement';

const TimelineGroup = () => {
  var currentDate = new Date();
  var currentYear = currentDate.getFullYear();

  return (
    <>
      <h1>Software Engineering Journey (2017-{currentYear})</h1>
      <div className="timelineContainer">
        <div className="timeline">
          <Timeline align="alternate">
            <TimelineElement date="December 2017" description="Started Programming" />
            <TimelineElement date="August 2018" description="Went to College" />
            <TimelineElement date="2020 January" description="Started IT Help Desk Job" />
            <TimelineElement date="2020 April" description="Got Assciate's Degree in Software Engineering" />
            <TimelineElement date="2020 May" description="Software Engineer at Cloud Focus Co., Ltd" />
            <TimelineElement date="2021 May" description="Software Engineer at Appvantage Pte Ltd" />
            <TimelineElement date="2022 April" description="Software Engineer at ******" />
          </Timeline>
      </div>
      </div>
      <hr/>
    </>
  );
}

export default TimelineGroup;

import React from 'react'
import './Contact.css'

const Contact = () => {

    return (
        <>
            <div className="contactCon">
                <h1 className="contactHeader">Connect with me on ...</h1>
                <figure className="socialIcons">
                    <a href="https://www.linkedin.com/in/swan-htet/" rel="noreferrer" target="_blank"><img src="./images/linkedin.png" className="contact" alt="linkedin"></img></a>
                    <a href="https://github.com/Swanhtet-grc" rel="noreferrer" target="_blank"><img src="./images/github.png" className="contact" alt="github"></img></a>
                </figure>
            </div>
        </>
    )
}

export default Contact;

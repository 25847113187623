import React from 'react'


const PageNotFound = () => {
  return (
    <div className='page-not-found'>
      <h1>Sorry, the page you are looking for doesn't exist!</h1>
    </div>
  );
}

export default PageNotFound;

import React from 'react'
import './Profile.css'

const Profile = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const startedYear = 2020

    const currentMonth = currentDate.getMonth()

    return (
            <>
                <div className="profile">
                    <h1 className="about-me">Welcome!</h1>
                    <img src="./profile-pic.png" className="profilePhoto" alt="Swan Htet Profile"></img>
                    <p className="text1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        I am Swan, a remote Software Engineer with {currentYear - startedYear}{currentMonth > 5 ? '+': ''} years of professional experience, currently working for a Singapore-based Company. I have a 
                        deep passion for Computer Science and Math. If you have any interesting things to share, let's connect.
                    </p>
                </div>
                <hr/>
            </>
    )
}

export default Profile;
